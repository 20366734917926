<template>
    <div class="case">
        <div class="banner">
            <Banner :type="'ABOUTUS'" :height="500"></Banner>
        </div>
        <div class="content">
            <div class="title">
                <span></span>
                我们的团队
            </div>
            <div class="about">
                <img class="fl" src="../index/image/guanyu.png" alt="">
                <div class="text fl">
                    <b>成都友乾人电子商务有限公司</b>
                    <p>我们是追求品质与力求不断超越自己的团队，每一位成员也是亲密的伙伴，与公司一起成长与发展。我们尊重每次合作的机会与挑战，不断精进，力求完美。团队秉承专注、专业的设计服务思维，让客户通过设计发挥产品的大价值，并发掘无限的可能。热爱设计并坚信设计的力量让我们不断前进…</p>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="title">
                <span></span>
                联系我们
            </div>
            <div class="contact">
                <div class="item fl">
                    <p>联系QQ：{{data.qq}}</p>
                    <p>联系邮箱：{{data.email}}</p>
                </div>
                <div class="item fr">
                    <p>手机号码：{{data.mobile}}</p>
                    <p>联系电话：{{data.telephone}}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" src="./about.scss" scoped></style>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import Banner from "../../components/banner";

export default {
    setup(props) {

        let { proxy } = getCurrentInstance();
        let data = reactive({
            email: "",
            mobile: "",
            qq: "",
            telephone: ""
        });

        onMounted(()=>{
            proxy.$axios("/info/get")
            .then((res)=>{
                data.email = res.data.email;
                data.mobile = res.data.mobile;
                data.qq = res.data.qq;
                data.telephone = res.data.telephone
            });
        })

        return{
            data
        }
    },
    components: {
        Banner
    }
};
</script>
